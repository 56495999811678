@import '../../styles/helpers/index.scss';

.why-we {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__inner {
    display: flex;
    gap: 45px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 70%;
      width: 100%;

      &:last-of-type {
        max-width: 30%;
      }

      @media screen and (max-width: $tablet-sm) {
        max-width: 100% !important;
      }
    }

    .section-title {
      max-width: 620px;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }

    p {
      margin: 50px 0 0;
      font-size: 18px;
      line-height: 155%;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }
    }
  }

  &__reasons {
    margin: 50px 0 0;
    @include grid(60px, 60px);

    @media screen and (max-width: $tablet-sm) {
      margin: 40px 0 0;
      grid-row-gap: 30px;
    }

    &--card {
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        align-items: center;
        gap: 25px;

        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 120%;
          max-width: 190px;
        }

        img {
          height: 65px;
          width: auto;
          object-fit: cover;
        }
      }

      p {
        font-size: 18px;
        line-height: 155%;

        @media screen and (max-width: $tablet-sm) {
          font-size: 16px;
        }
      }
    }
  }

  .form-wrapper {
    max-width: 420px;
    width: 100%;
    padding: 70px 40px;
    background-color: map-get($colors, 'yellow');

    @media screen and (max-width: $tablet-sm) {
      padding: 50px 30px;
    }

    h4 {
      margin: 0 0 40px;
      font-weight: 700;
      font-size: 26px;
      line-height: 31px;
      text-transform: uppercase;
    }
  }
}
