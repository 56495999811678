@import '../../styles/helpers/index.scss';

.services {
  margin: 120px 0 0;
  padding: 100px 0 150px;
  background-color: map-get($colors, 'yellow');

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 70px 0 0;

    &--card {
      @include align-center;
      flex-direction: column;
      justify-content: center;
      padding: 50px 0 40px;
      margin: 0 20px 0 0;
      height: 300px;
      background-color: #fff;
      text-decoration: none !important;
      text-align: center;
      @include scale;

      @media screen and (max-width: $tablet-sm) {
        margin: 0;
      }

      img {
        height: 80px;
        margin: 0 auto;
      }

      h4 {
        margin: 30px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #070707;
      }
    }
  }

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 60px;
      height: 60px;
      background: #fff;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: -50px;
      cursor: pointer;

      transition: background 0.3s ease;

      &:hover {
        background: map-get($colors, 'yellow');
      }

      &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;

        @media screen and (max-width: $tablet-sm) {
          top: 15px;
          left: 18px;
          width: 15px;
          height: 15px;
        }
      }

      @media screen and (max-width: $tablet-sm) {
        top: -30px;
        width: 45px;
        height: 45px;
      }
    }
    .slick-next {
      right: 20px;

      &::before {
        background-image: url('../../static/arrow-right.svg');
      }

      @media screen and (max-width: $tablet-sm) {
        right: 0;
      }
    }

    .slick-prev {
      right: 100px;
      left: unset;

      &::before {
        background-image: url('../../static/arrow-left.svg');
      }

      @media screen and (max-width: $tablet-sm) {
        right: auto;
        left: 0;
      }
    }
  }

  .slick-slide {
    & > div {
      position: relative;
      &::before {
        background-image: url('../../static/link-arrow.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        height: 26px;
        position: absolute;
        right: 30px;
        top: 10px;
        width: 30px;
        z-index: 2;

        @media screen and (max-width: $tablet-sm) {
          right: 10px;
        }
      }
    }
  }
}
