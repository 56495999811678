@import '../../styles/helpers/index.scss';

.page-header {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: right;
  z-index: 100;
  transition: box-shadow 0.3s ease;
  background-color: #fff;

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
  }

  .container {
    @include space-between;
    align-items: center;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__logo {
    display: block;

    img {
      max-height: 70px;

      @media screen and (max-width: $tablet-sm) {
        max-height: 50px;
      }
    }
  }

  &__links {
    display: flex;
    gap: 25px;

    @media screen and (max-width: $tablet-sm) {
      margin: 20px 0 0;
    }

    & > .grey {
      text-indent: 30px;

      @media screen and (max-width: $tablet-sm) {
        text-indent: 20px;
      }

      &::before {
        content: '';
        background-image: url('../../static/phone.svg');
        background-size: cover;

        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);

        width: 20px;
        height: 20px;

        @media screen and (max-width: $tablet-sm) {
          width: 15px;
          height: 15px;
          left: 15px;
        }
      }
    }
  }
}
