@import '../../styles/helpers/index.scss';

.cta {
  margin: 120px 0 0;
  padding: 150px 0 160px;

  background-image: url('../../static/cta_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    padding: 90px 0;
  }

  .section-title {
    color: #fff;
  }

  &__links {
    margin: 60px 0 0;
    @include align-center;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      gap: 15px;
    }

    span,
    span > a {
      font-size: 24px;
      font-weight: 700;
      color: #fff;

      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
      }
    }
  }
}
