@use 'scss-reset/reset';

@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import './helpers/variables.scss';
@import './helpers/mixins.scss';

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Gilroy';

  overflow: auto;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding-top: 120px;

    @media screen and (max-width: $tablet-sm) {
      padding-top: 100px;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  textarea {
    resize: none;
  }
}

.btn {
  position: relative;
  padding: 15px 20px;

  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  line-height: 1;

  @media screen and (max-width: $tablet-sm) {
    padding: 12px 15px;
    font-size: 14px;
  }

  &.grey {
    color: #fff;
    background-color: map-get($colors, 'grey');
    @include scale;
  }

  &.yellow {
    color: map-get($colors, 'text');
    background-color: map-get($colors, 'yellow');
    @include shine;
  }
}

.section-title {
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;

  @media screen and (max-width: $tablet-sm) {
    font-size: 32px;
  }
}
