@import '../../styles/helpers/index.scss';

.areas {
  margin: 120px 0 0;
  padding: 70px 0 140px;
  background-image: url('../../static/areas_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    padding: 70px 0;
  }

  .section-title {
    color: map-get($colors, 'yellow');
  }

  ul {
    margin: 45px 0 0;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
      margin: 30px 0 0;
    }

    li {
      position: relative;
      color: #fff;
      font-size: 18px;
      line-height: 200%;
      text-indent: 25px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }

      &::before {
        content: '';
        background-image: url('../../static/list-arrows.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 8px;

        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
