@import '../../styles/helpers/index.scss';

.container {
  position: relative;
  max-width: 1240px;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  padding: 0;

  @media screen and (max-width: $desktop-sm) {
    max-width: 100%;
    padding: 0 15px;
    width: auto;
  }
}

.mobile-cta-buttons {
  display: none;

  position: fixed;
  z-index: 100;
  left: 0;
  width: 100%;
  height: 60px;

  background: #fff;
  box-shadow: 0px 0px 10px 3px rgb(7 7 7 / 50%);

  transition: all 0.3s ease;

  a {
    @include align-center;
    justify-content: center;
    max-width: 50%;
    width: 100%;
    font-weight: 700;
    color: #fff;
    line-height: 100%;

    &:first-of-type {
      background-color: map-get($colors, 'yellow');
      position: relative;

      &::before {
        content: '';
        background-image: url('../../static/phone.svg');
        background-size: cover;

        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);

        width: 20px;
        height: 20px;
      }
    }

    &:last-of-type {
      background-color: map-get($colors, 'grey');
    }
  }

  &.off {
    bottom: -60px;
  }

  &.on {
    bottom: 0;
  }

  @media screen and (max-width: $tablet-sm) {
    display: flex;
  }
}
