@import '../../styles/helpers/index.scss';

.hero {
  .container {
    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }
  }

  &__content {
    padding: 70px 50px 0 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tablet-sm) {
      padding: 70px 0 0;
    }

    h1 {
      font-weight: 700;
      font-size: 60px;
      line-height: 120%;
      text-transform: uppercase;

      @media screen and (max-width: $tablet-sm) {
        font-size: 38px;
      }
    }

    &--awards {
      margin: 45px 0 0;
      @include space-between;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }

      img {
        height: 80px;
        width: auto;
        object-fit: cover;

        @include scale;

        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🏆</text></svg>")
            16 0,
          auto;

        @media screen and (max-width: $tablet-sm) {
          height: 50px;
        }
      }
    }

    &--links {
      margin: 60px 0 0;
      @include align-center;
      gap: 20px;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
        gap: 15px;
      }

      span,
      span > a {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, 'text');

        @media screen and (max-width: $tablet-sm) {
          font-size: 18px;
        }
      }
    }
  }

  &__ratings {
    background-image: url('../../static/hero_bg.png');
    background-size: cover;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    padding: 0 70px 40px;

    @media screen and (max-width: $tablet-sm) {
      background-image: none;
      padding: 20px 0 40px;
    }

    &--wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        grid-template-columns: 1fr;
      }

      & > div {
        @media screen and (max-width: $tablet-sm) {
          grid-area: unset !important;
        }
        &:nth-of-type(1) {
          grid-area: 1 / 1 / 2 / 2;
        }

        &:nth-of-type(2) {
          grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-of-type(3) {
          grid-area: 2 / 1 / 3 / 3;

          span {
            display: none;

            @media screen and (max-width: $tablet-sm) {
              display: block;
            }
          }
        }
      }
    }

    &--card {
      padding: 15px 0;
      background-color: rgba(255, 255, 255, 0.7);
      @include space-around;
      align-items: center;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        justify-content: space-between;
        background-color: rgba(map-get($colors, 'yellow'), 0.15);
      }

      &:hover {
        text-decoration: none !important;
      }

      & > img {
        height: 50px;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 auto;
        }
      }

      & > div {
        text-align: center;

        @media screen and (max-width: $tablet-sm) {
          max-width: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        span {
          display: block;
          margin: 0 0 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 110%;
          color: map-get($colors, 'dark-blue');
        }
      }
    }
  }
}
