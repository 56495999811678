@import '../../styles/helpers/index.scss';

.rating-stars {
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: $tablet-sm) {
    gap: 5px;
    justify-content: center;
  }
}
