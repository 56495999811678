@import '../../styles/helpers/index.scss';

.about {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  .section-title {
    margin: 0 0 30px;
  }

  &__content {
    max-width: 60%;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
    }

    div {
      font-size: 16px;
      line-height: 140%;
    }

    ul {
      margin: 15px 0;
      text-indent: 20px;

      li {
        position: relative;
        &::before {
          content: '';
          background-image: url('../../static/arrow-right.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 12px;
          height: 8px;

          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__image {
    max-width: 40%;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
