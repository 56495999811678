@import '../../styles/helpers/index.scss';

form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  input,
  textarea,
  select {
    height: 50px;
    line-height: 50px;
    outline: none;
    font-family: 'Gilroy';
    &:not([type='submit']) {
      width: 100%;
      padding: 0 25px;
      border: none;
      color: map-get($colors, 'text');
      font-size: 16px;
      line-height: 155%;
      appearance: none;
      -webkit-appearance: none;
      &::placeholder {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: #404040;
      }
    }
  }

  input[type='submit'] {
    width: 120px;
    border: 1px solid map-get($colors, 'grey');
    transition: all 0.3s ease !important;
    cursor: pointer;

    &:hover {
      transform: none !important;
      color: map-get($colors, 'grey');
      background: #fff;
    }
  }

  .select-wrapper {
    position: relative;
    &::after {
      content: '▼';
      font-size: 12px;
      top: 20px;
      right: 15px;
      position: absolute;
    }
  }

  textarea {
    height: 120px;
    padding: 10px 25px !important;
  }

  .data-protection {
    display: flex;
    gap: 5px;
    line-height: 1.24;

    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 30px;
    }

    span {
      display: inline-block;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

#book-now {
  .booking {
    max-width: 500px;
    margin: 30px auto;
    padding: 15px;

    text-align: center;

    form {
      margin: 30px 0 0;

      input:not([type='submit']),
      textarea,
      select {
        border: 1px solid #404040;
      }
    }
  }
}
