@import '../../styles/helpers/index.scss';

.satisfaction-guarantee {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 50px 0 0;

    @include grid($columns: repeat(3, 1fr));
  }

  &__card {
    @include scale;
    padding: 60px 0 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;

    @include align-center;
    flex-direction: column;

    & > img {
      height: 50px;
      object-fit: cover;
    }

    .rating-stars {
      margin: 30px 0 0;
      justify-content: center;
    }
  }
}
