@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 100px 0 25px;
  background-color: map-get($colors, 'yellow');
  text-align: center;

  @media screen and (max-width: $tablet-sm) {
    padding: 80px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    display: flex;
    width: 270px;
    height: 70px;
    margin: 0 auto 50px;

    @media screen and (max-width: $tablet-sm) {
      width: 200px;
      height: auto;
    }

    img {
      object-fit: cover;
    }
  }

  &__phone {
    color: map-get($colors, 'text');
    font-size: 40px;
    font-weight: 700;
  }

  &__email,
  &__address {
    margin: 35px 0 0;
    color: map-get($colors, 'text');
    font-size: 24px;
    font-weight: 400;
  }

  &__underline {
    padding: 20px 0 0;
    margin: 70px 0 0;
    border-top: 1px solid #070707;
    font-size: 12px;

    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: center;
      margin: 50px 0 0;
    }

    .created-by {
      @media screen and (max-width: $tablet-sm) {
        order: 3;
      }

      &:hover {
        text-decoration: none !important;
        strong {
          text-decoration: underline;
        }
      }
    }

    a {
      color: map-get($colors, 'text');
    }

    &--links {
      display: flex;
      gap: 15px;

      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0;
      }
    }
  }
}
