@import '../../styles/helpers/index.scss';

.brands {
  position: relative;
  margin: 140px 0 0;
  padding: 0 0 140px;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__nav {
    @include align-center;
    justify-content: space-between;

    button {
      position: relative;
      border: 1px solid map-get($colors, 'orange');
      background-color: #fff;
      width: 50px;
      height: 50px;

      cursor: pointer;

      &::before {
        content: '';
        width: 33px;
        height: 16px;
        background-image: url('../../static/arrow-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:disabled {
        background-color: #eee;
        border-color: #eee;
        cursor: not-allowed;
        opacity: 0.7;
      }

      &.prev::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  &__slider {
    @include grid($columns: repeat(5, 1fr), $rows: repeat(5, 1fr), $row-gap: 1px, $column-gap: 1px);
    background-color: rgba(42, 106, 209, 0.16);

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    li {
      position: relative;
      padding: 15px;
      background: #fff;

      span {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      img {
        margin: 0 auto;
        height: auto;
        width: 70%;
        object-fit: cover;

        @media screen and (max-width: $tablet-sm) {
          width: 100%;
        }
      }
    }
  }

  &__list {
    margin: 60px 0 0;
    columns: 3;
    padding: 0 30px;

    @media screen and (max-width: $tablet-sm) {
      columns: 1;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }

      &::before {
        content: '';
        background-image: url('../../static/list-arrows.svg');
        left: -30px;
        position: absolute;
        width: 13px;
        height: 10px;
      }

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }
}
