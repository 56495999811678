@import '../../styles/helpers/index.scss';

.counter {
  margin: 50px 0 0;
  padding: 80px 0 215px;
  background-color: #ececec;

  @media screen and (max-width: $tablet-sm) {
    padding: 80px 0;
  }

  &__wrapper {
    @include grid($columns: repeat(3, 1fr));
  }

  &__item {
    @include align-center;
    flex-direction: column;
    @include scale;

    span {
      display: block;

      &:first-of-type {
        font-weight: 900;
        font-size: 70px;
        line-height: 100%;
        color: map-get($colors, 'yellow');
      }

      &:last-of-type {
        margin: 25px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

        @media screen and (max-width: $tablet-sm) {
          margin: 15px 0 0;
        }
      }
    }
  }
}
